// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SHOW_EDUCATION_MODAL = 'SHOW_EDUCATION_MODAL';
export const HIDE_EDUCATION_MODAL = 'HIDE_EDUCATION_MODAL';
export const SHOW_ADDRESS_MODAL = 'SHOW_ADDRESS_MODAL';
export const HIDE_ADDRESS_MODAL = 'HIDE_ADDRESS_MODAL';
export const SHOW_EXPERIENCE_MODAL = 'SHOW_EXPERIENCE_MODAL';
export const HIDE_EXPERIENCE_MODAL = 'HIDE_EXPERIENCE_MODAL';


//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_INPUT = 'SHOW_INPUT';
export const HIDE_INPUT = 'HIDE_INPUT';
export const SHOW_CONFETTI = 'SHOW_CONFETTI';
export const HIDE_CONFETTI = 'HIDE_CONFETTI';
export const HANDLE_DISABLE_SUBMIT_BUTTON = 'HANDLE_DISABLE_SUBMIT_BUTTON';
export const HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON = 'HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const SET_SIGNIN_ERROR = 'SET_SIGNIN_ERROR';

// export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';



//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_ALL_CONTACT_SUCCESS = 'GET_ALL_CONTACT_SUCCESS';
export const GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS = 'GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS';
export const GET_ALL_CONTACT_FAILED = 'GET_ALL_CONTACT_FAILED';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_FAILED = 'ON_ADD_CONTACT_FAILED';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';
export const GET_POC_LIST_SUCCESS = 'GET_POC_LIST_SUCCESS';
export const GET_POC_LIST_FAILED = 'GET_POC_LIST_FAILED';
export const ON_ADD_POC_SUCCESS = 'ON_ADD_POC_SUCCESS';
export const ON_ADD_POC_FAILED = 'ON_ADD_POC_FAILED';
export const REMOVED_DUPLICATES_SUCCESS = 'REMOVED_DUPLICATES_SUCCESS';

//Client

export const GET_ALL_CLIENT_SUCCESS = 'GET_ALL_CLIENT_SUCCESS';
export const GET_ALL_CLIENT_FAILED = 'GET_ALL_CLIENT_FAILED';
export const GET_ALL_VENDOR_SUCCESS = 'GET_ALL_VENDOR_SUCCESS';
export const GET_ALL_VENDOR_FAILED = 'GET_ALL_VENDOR_FAILED';
export const GET_ALL_PARTNER_SUCCESS = 'GET_ALL_PARTNER_SUCCESS';
export const GET_ALL_PARTNER_FAILED = 'GET_ALL_PARTNER_FAILED';
export const GET_MERGE_CLIENT_LIST_SUCCESS = 'GET_MERGE_CLIENT_LIST_SUCCESS';
export const RESET_MERGE_CLIENT_LIST = 'RESET_MERGE_CLIENT_LIST';


// Consultant
export const ADD_CONSULTANT_SUCCESS = 'ADD_CONSULTANT_SUCCESS';
export const ADD_CONSULTANT_FAILURE = 'ADD_CONSULTANT_FAILURE';
export const GET_CONSULTANTS_SUCCESS = 'GET_CONSULTANTS_SUCCESS';
export const GET_CONSULTANTS_FAILURE = 'GET_CONSULTANTS_FAILURE';
export const GET_TEAM_CONSULTANTS_SUCCESS = 'GET_TEAM_CONSULTANTS_SUCCESS';
export const GET_TEAM_CONSULTANTS_FAILURE = 'GET_TEAM_CONSULTANTS_FAILURE';
export const EDIT_CONSULTANT_SUCCESS = 'EDIT_CONSULTANT_SUCCESS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_ATTACHMENTS_SUCCESS = 'FETCH_ATTACHMENTS_SUCCESS';
export const EDIT_CONSULTANT_FAILURE = 'EDIT_CONSULTANT_FAILURE';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';
export const FETCH_ATTACHMENTS_FAILURE = 'FETCH_ATTACHMENTS_FAILURE';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const GET_CONSULTANT_SUCCESS = 'GET_CONSULTANT_SUCCESS';
export const GET_CONSULTANT_FAILURE = 'GET_CONSULTANT_FAILURE';
export const DELETE_CONSULTANT_SUCCESS = 'DELETE_CONSULTANT_SUCCESS';
export const DELETE_CONSULTANT_FAILURE = 'DELETE_CONSULTANT_FAILURE';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE';
export const DRAG_HAPPENED = 'DRAG_HAPPENED';
export const DRAG_FAILED = 'DRAG_FAILED';
export const FETCH_SCREENER_SUCCESS = 'FETCH_SCREENER_SUCCESS';
export const ADD_SCREENER_CALENDAR_SUCCESS = 'ADD_SCREENER_CALENDAR_SUCCESS';
export const FETCH_SCREENER_FAILURE = 'FETCH_SCREENER_FAILURE';
export const ADD_SCREENER_CALENDAR_FAILURE = 'ADD_SCREENER_CALENDAR_FAILURE';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';
export const SELECT_SCREENER_SUCCESS = 'SELECT_SCREENER_SUCCESS';
export const FETCH_MY_SCREENINGS_SUCCESS = 'FETCH_MY_SCREENINGS_SUCCESS';
export const FETCH_SCHEDULED_SCREENINGS_SUCCESS = 'FETCH_SCHEDULED_SCREENINGS_SUCCESS';
export const SELECT_SCREENER_FAILURE = 'SELECT_SCREENER_FAILURE';
export const FETCH_MY_SCREENINGS_FAILURE = 'FETCH_MY_SCREENINGS_FAILURE';
export const FETCH_SCHEDULED_SCREENINGS_FAILURE = 'FETCH_SCHEDULED_SCREENINGS_FAILURE';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
export const CONSULTANT_INIT = 'CONSULTANT_INIT';
export const UPDATE_SCREENING_SUCCESS = 'UPDATE_SCREENING_SUCCESS';
export const UPDATE_SCREENING_FAILURE = 'UPDATE_SCREENING_FAILURE';
export const ADD_MARKETING_PROFILE_SUCCESS = 'ADD_MARKETING_PROFILE_SUCCESS';
export const ADD_MARKETING_PROFILE_FAILURE = 'ADD_MARKETING_PROFILE_FAILURE';
export const FETCH_MARKETING_PROFILE_SUCCESS = 'FETCH_MARKETING_PROFILE_SUCCESS';
export const FETCH_MARKETING_PROFILE_FAILURE = 'FETCH_MARKETING_PROFILE_FAILURE';
export const FETCH_MARKETING_PROFILE_LIST_SUCCESS = 'FETCH_MARKETING_PROFILE_LIST_SUCCESS';
export const FETCH_MARKETING_PROFILE_LIST_FAILURE = 'FETCH_MARKETING_PROFILE_LIST_FAILURE';
export const FETCH_TEAM_MARKETING_PROFILE_SUCCESS = 'FETCH_TEAM_MARKETING_PROFILE_SUCCESS';
export const FETCH_TEAM_MARKETING_PROFILE_FAILURE = 'FETCH_TEAM_MARKETING_PROFILE_FAILURE';
export const UPDATE_MARKETING_PROFILE_SUCCESS = 'UPDATE_MARKETING_PROFILE_SUCCESS';
export const UPDATE_MARKETING_PROFILE_FAILURE = 'UPDATE_MARKETING_PROFILE_FAILURE';
export const FETCH_ONE_MARKETING_PROFILE_SUCCESS = 'FETCH_ONE_MARKETING_PROFILE_SUCCESS';
export const FETCH_ONE_MARKETING_PROFILE_FAILURE = 'FETCH_ONE_MARKETING_PROFILE_FAILURE';

export const GET_SCREENING_CONSULTANTS_SUCCESS = 'GET_SCREENING_CONSULTANTS_SUCCESS';
export const GET_SCREENING_CONSULTANTS_FAILURE = 'GET_SCREENING_CONSULTANTS_FAILURE';
export const GET_MARKETING_CONSULTANTS_SUCCESS = 'GET_MARKETING_CONSULTANTS_SUCCESS';
export const GET_MARKETING_CONSULTANTS_FAILURE = 'GET_MARKETING_CONSULTANTS_FAILURE';

export const GET_SEARCHED_CONSULTANT_SUCCESS = 'GET_SEARCHED_CONSULTANT_SUCCESS';
export const GET_SEARCHED_CONSULTANT_FAILURE = 'GET_SEARCHED_CONSULTANT_FAILURE';

export const FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS = 'FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS';

// Account details

export const FETCH_ACCOUNT_DETAILS_LIST_SUCCESS = 'FETCH_ACCOUNT_DETAILS_LIST_SUCCESS';
export const FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS = 'FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS';
export const FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS = 'FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS';
export const FETCH_CONSULTANT__ADDTIONAL_INFO_FORM_VALUE_SUCCESS = 'FETCH_CONSULTANT__ADDTIONAL_INFO_FORM_VALUE_SUCCESS';
export const FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS = 'FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS';
export const FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS = 'FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS';
export const FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS = 'FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS';
export const FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS = 'FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS';


// USER
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const FETCH_EDUCATION_LIST_SUCCESS = 'FETCH_EDUCATION_LIST_SUCCESS';
export const FETCH_ADDRESS_LIST_SUCCESS = 'FETCH_ADDRESS_LIST_SUCCESS';
export const FETCH_PRACTICE_LIST_SUCCESS = 'FETCH_PRACTICE_LIST_SUCCESS';
export const FETCH_EXPERIENCE_LIST_SUCCESS = 'FETCH_EXPERIENCE_LIST_SUCCESS';
export const FETCH_USER_EDUCATION_SUCCESS = 'FETCH_USER_EDUCATION_SUCCESS';
export const FETCH_USER_ADDRESS_SUCCESS = 'FETCH_USER_ADDRESS_SUCCESS';
export const FETCH_USER_PRACTICE_SUCCESS = 'FETCH_USER_PRACTICE_SUCCESS';
export const FETCH_EDUCATION_LIST_FAILURE = 'FETCH_EDUCATION_LIST_FAILURE';
export const FETCH_ADDRESS_LIST_FAILURE = 'FETCH_ADDRESS_LIST_FAILURE';
export const FETCH_PRACTICE_LIST_FAILURE = 'FETCH_PRACTICE_LIST_FAILURE';
export const FETCH_EXPERIENCE_LIST_FAILURE = 'FETCH_EXPERIENCE_LIST_FAILURE';
export const FETCH_USER_EDUCATION_FAILURE = 'FETCH_USER_EDUCATION_FAILURE';
export const FETCH_USER_ADDRESS_FAILURE = 'FETCH_USER_ADDRESS_FAILURE';
export const FETCH_USER_PRACTICE_FAILURE = 'FETCH_USER_PRACTICE_FAILURE';
export const FETCH_USER_EXPERIENCE_SUCCESS = 'USER_EXPERIENCE_SUCCESS';
export const FETCH_USER_EXPERIENCE_FAILURE = 'USER_EXPERIENCE_FAILURE';
export const USER_INIT = 'USER_INIT';
export const FETCH_PREVIOUS_CLIENT_LIST_SUCCESS = 'FETCH_PREVIOUS_CLIENT_LIST_SUCCESS';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_PIE_DATA_FAILURE = 'FETCH_PIE_DATA_FAILURE';
export const FETCH_BAR_DATA_FAILURE = 'FETCH_BAR_DATA_FAILURE';
export const FETCH_DONUT_DATA_FAILURE = 'FETCH_DONUT_DATA_FAILURE';
export const FETCH_HIRED_DATA_SUCCESS = 'FETCH_HIRED_DATA_SUCCESS';
export const FETCH_RATIO_DATA_SUCCESS = 'FETCH_RATIO_DATA_SUCCESS';
export const FETCH_BAR_DATA_SUCCESS = 'FETCH_BAR_DATA_SUCCESS';
export const FETCH_HIRED_DATA_FAILURE = 'FETCH_HIRED_DATA_FAILURE';
export const FETCH_RATIO_DATA_FAILURE = 'FETCH_RATIO_DATA_FAILURE';
export const FETCH_PIE_DATA_SUCCESS = 'FETCH_PIE_DATA_SUCCESS';
export const FETCH_DONUT_DATA_SUCCESS = 'FETCH_DONUT_DATA_SUCCESS';
export const FETCH_RECENT_SUCCESS = 'FETCH_RECENT_SUCCESS';
export const FETCH_RECENT_FAILURE = 'FETCH_RECENT_FAILURE';
export const FETCH_STATUS_SUCCESS  = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE';
export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_PRACTICE_SUCCESS = 'ADD_PRACTICE_SUCCESS';
export const ADD_EXPERIENCE_SUCCESS = 'ADD_EXPERIENCE_SUCCESS';
export const ADD_EDUCATION_FAILURE = 'ADD_EDUCATION_FAILURE';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';
export const ADD_PRACTICE_FAILURE = 'ADD_PRACTICE_FAILURE';
export const ADD_EXPERIENCE_FAILURE = 'ADD_EXPERIENCE_FAILURE';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_LIST_SUCCESS = 'FETCH_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';
export const FETCH_EMPLOYEE_LIST_FAILURE = 'FETCH_EMPLOYEE_LIST_FAILURE';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';
export const FETCH_RECRUITERS_SUCCESS  = 'FETCH_RECRUITERS_SUCCESS';
export const FETCH_RECRUITERS_FAILURE = 'FETCH_RECRUITERS_FAILURE';

// Employee constants

export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';
export const GET_MANAGERS_FAILURE = 'GET_MANAGERS_FAILURE';
export const GET_MARKETERS_SUCCESS = 'GET_MARKETERS_SUCCESS';
export const GET_MARKETERS_FAILURE = 'GET_MARKETERS_FAILURE';
export const GET_MARKETING_LEAD_FAILURE = 'GET_MARKETING_LEAD_FAILURE';
export const GET_MARKETING_LEAD_SUCCESS = 'GET_MARKETING_LEAD_SUCCESS';
export const GET_RECRUITMENT_LEAD_SUCCESS = `GET_RECRUITMENT_LEAD_SUCCESS`;
export const GET_RECRUITMENT_LEAD_FAILURE = `GET_RECRUITMENT_LEAD_FAILURE`;
export const GET_EMPLOYERS_SUCCESS = 'GET_EMPLOYERS_SUCCESS';
export const GET_EMPLOYERS_FAILURE = 'GET_EMPLOYERS_FAILURE';
export const ASSIGN_MARKETER_SUCCESS = 'ASSIGN_MARKETER_SUCCESS';
export const ASSIGN_MARKETER_FAILURE = 'ASSIGN_MARKETER_FAILURE';
export const ASSIGN_LEAD_SUCCESS = 'ASSIGN_LEAD_SUCCESS';
export const ASSIGN_LEAD_FAILURE = 'ASSIGN_LEAD_FAILURE';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_ATTACHMENT_SUCCESS = 'GET_ATTACHMENT_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';
export const GET_ATTACHMENT_FAILURE = 'GET_ATTACHMENT_FAILURE';
export const EMPLOYEE_INIT = 'EMPLOYEE_INIT';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAILURE = 'GET_LEVELS_FAILURE';
export const GET_MARKETING_SUBORDINATES_SUCCESS = 'GET_MARKETING_SUBORDINATES_SUCCESS';
export const GET_MARKETING_SUBORDINATES_FAILURE = 'GET_MARKETING_SUBORDINATES_FAILURE';

// Submission constants

export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS';
export const FETCH_SUBMISSIONS_FAILED = 'FETCH_SUBMISSIONS_FAILED';
export const FETCH_PENDING_SUBMISSIONS_SUCCESS = 'FETCH_PENDING_SUBMISSIONS_SUCCESS';
export const FETCH_PENDING_SUBMISSIONS_FAILED = 'FETCH_PENDING_SUBMISSIONS_FAILED';
export const FETCH_ALL_SUBMISSIONS_SUCCESS = 'FETCH_ALL_SUBMISSIONS_SUCCESS';
export const FETCH_ALL_SUBMISSIONS_FAILED = 'FETCH_ALL_SUBMISSIONS_FAILED';
export const FETCH_TEAM_SUBMISSIONS_SUCCESS = 'FETCH_TEAM_SUBMISSIONS_SUCCESS';
export const FETCH_TEAM_SUBMISSIONS_FAILED = 'FETCH_TEAM_SUBMISSIONS_FAILED';
export const FETCH_SUBMISSION_SUCCESS = 'FETCH_SUBMISSION_SUCCESS';
export const FETCH_SUBMISSION_FAILED = 'FETCH_SUBMISSION_FAILED';
export const ADD_SUBMISSION_SUCCESS = 'ADD_SUBMISSION_SUCCESS';
export const ADD_SUBMISSION_FAILED = 'ADD_SUBMISSION_FAILED';
export const EDIT_SUBMISSION_SUCCESS = 'EDIT_SUBMISSION_SUCCESS';
export const EDIT_SUBMISSION_FAILED = 'EDIT_SUBMISSION_FAILED';
export const FETCH_SUBMISSION_ATTACHMENTS_SUCCESS = 'FETCH_SUBMISSION_ATTACHMENTS_SUCCESS';
export const FETCH_SUBMISSION_ATTACHMENTS_FAILURE = 'FETCH_SUBMISSION_ATTACHMENTS_FAILURE';

export const SUBMISSION_INIT = 'SUBMISSION_INIT';
export const SELECTED_SUBMISSION = 'SELECTED_SUBMISSION';
export const SELECTED_ASSESSMEN_TYPE = 'SELECTED_ASSESSMEN_TYPE';

// assessment constants

export const FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS';
export const FETCH_ASSESSMENT_FAILED = 'FETCH_ASSESSMENT_FAILED';
export const DELETE_ASSESSMENT_SUCCESS = `DELETE_ASSESSMENT_SUCCESS`;
export const DELETE_ASSESSMENT_FAILURE = `DELETE_ASSESSMENT_FAILURE`;
export const EDIT_ASSESSMENT_SUCCESS = 'EDIT_ASSESSMENT_SUCCESS';
export const EDIT_ASSESSMENT_FAILED = 'EDIT_ASSESSMENT_FAILED';
export const FETCH_ASSESSMENT_LIST_SUCCESS = 'FETCH_ASSESSMENT_LIST_SUCCESS';
export const FETCH_ASSESSMENT_LIST_FAILED = 'FETCH_ASSESSMENT_LIST_FAILED';
export const FETCH_TEAM_ASSESSMENTS_SUCCESS = 'FETCH_TEAM_ASSESSMENTS_SUCCESS';
export const FETCH_TEAM_ASSESSMENTS_FAILED = 'FETCH_TEAM_ASSESSMENTS_FAILED';


// offer constants

export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILED = 'FETCH_OFFER_FAILED';
export const FETCH_TEAM_OFFER_SUCCESS = 'FETCH_TEAM_OFFER_SUCCESS';
export const FETCH_TEAM_OFFER_FAILED = 'FETCH_TEAM_OFFER_FAILED';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const ADD_OFFER_FAILED = 'ADD_OFFER_FAILED';
export const DELETE_OFFER_SUCCESS = `DELETE_OFFER_SUCCESS`;
export const DELETE_OFFER_FAILURE = `DELETE_OFFER_FAILURE`;
export const EDIT_OFFER_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILED = 'EDIT_OFFER_FAILED';
export const FETCH_SUPPORT_ID_LIST_SUCCESS = 'FETCH_SUPPORT_ID_LIST_SUCCESS';
export const FETCH_MARKETER_LIST_SUCCESS = 'FETCH_MARKETER_LIST_SUCCESS';
export const FETCH_SUPPORT_ID_LIST_FAILURE = 'FETCH_SUPPORT_ID_LIST_FAILURE';

//project Coordinator
export const FETCH_PROJECT_COORDINATORS_LIST_SUCCESS = 'FETCH_PROJECT_COORDINATORS_LIST_SUCCESS';
export const FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS = 'FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS';
export const FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS = 'FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS';
export const FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS = 'FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS';

export const FETCH_PROJECT_COORDINATORS_OFFER_FAILED = 'FETCH_PROJECT_COORDINATORS_OFFER_FAILED';
export const FETCH_SUPPORT_PERSON_LIST_SUCCESS = 'FETCH_SUPPORT_PERSON_LIST_SUCCESS';
export const FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS = 'FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS';
export const FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS = 'FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS';

// engineer 
export const FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS = 'FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS';
export const FETCH_SINGLE_ENGINEER_OFFER_SUCCESS = 'FETCH_SINGLE_ENGINEER_OFFER_SUCCESS';
export const FETCH_ENGINEER_OFFER_LIST_SUCCESS = 'FETCH_ENGINEER_OFFER_LIST_SUCCESS';
export const FETCH_ENGINEER_OFFER_FAILED = 'FETCH_ENGINEER_OFFER_FAILED';
export const FETCH_TIMESHEET_CONFIG_LIST_SUCCESS = 'FETCH_TIMESHEET_CONFIG_LIST_SUCCESS';
export const FETCH_TIMESHEET_HOURS_LIST_SUCCESS = 'FETCH_TIMESHEET_HOURS_LIST_SUCCESS';
export const FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS = 'FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS';
export const FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS = 'FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS';

//contract team
export const FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS = 'FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS';
export const FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS = 'FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS';
export const FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS = 'FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS';
export const FETCH_CONTRACT_VERIFIED_LIST_SUCCESS = 'FETCH_CONTRACT_VERIFIED_LIST_SUCCESS';

//consultant 
export const FETCH_CONSULTANT_OFFER_LIST_SUCCESS = 'FETCH_CONSULTANT_OFFER_LIST_SUCCESS';


// fething the offers for analytics table
export const FETCH_MONTHLY_OFFER_SUCCESS = 'FETCH_MONTHLY_OFFER_SUCCESS';

// standup constants
export const FETCH_MARKETING_STANDUP_LIST_SUCCESS = `FETCH_MARKETING_STANDUP_LIST_SUCCESS`;
export const FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS = `FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS`;

//Static constants

export const FETCH_VISA_SUCCESS = 'FETCH_VISA_SUCCESS';
export const FETCH_VISA_FAILED = 'FETCH_VISA_FAILED';
export const FETCH_SOURCE_SUCCESS = 'FETCH_SOURCE_SUCCESS';
export const FETCH_SOURCE_FAILED = 'FETCH_SOURCE_FAILED';

// error response save
export const HANDLE_ERROR_RESPONSE = 'HANDLE_ERROR_RESPONSE';



// notification manage

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const READ_NOTIFICATIONS_SUCCESS =  `READ_NOTIFICATIONS_SUCCESS`;
export const READ_NOTIFICATIONS_FAILURE = 'READ_NOTIFICATIONS_FAILURE';

// marketing dashboard
export const FETCH_ALL_OFFERS_COUNT = 'FETCH_ALL_OFFERS_COUNT';
export const FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS = 'FETCH_ALL_MARKETER_DASHBOARD_ANALYTICS';
export const FETCH_ALL_COUNT_TO_STATUS_MARKETING = 'FETCH_ALL_COUNT_TO_STATUS_MARKETING';
export const FETCH_ASSIGNED_CONSULTANT = 'FETCH_ASSIGNED_CONSULTANT';
export const FETCH_PNL_OFFERS = 'FETCH_PNL_OFFERS';
export const FETCH_TOTAL_ONGING_OFFERS = 'FETCH_TOTAL_ONGING_OFFERS';
export const FETCH_RECRUITERS_CONSULTANT_CONST = `FETCH_RECRUITERS_CONSULTANT_CONST`;
export const FETCH_PROJECT_TYPE_PNL_DATA = `FETCH_PROJECT_TYPE_PNL_DATA`;
export const FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL = `FETCH_TOTAL_DATA_MARKETING_PROFILE_TOTAL`;
export const FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP = `FETCH_ASSIGNED_CONSULTANT_DATA_BY_GROUP`;
export const FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS = `FETCH_MONTHWISE_SUBMISSION_AND_ASSESSMENT_SUCCESS`;
export const FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS =`FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS`;
export const FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS =`FETCH_CURRENT_WEEK_OFFERS_LIST_SUCCESS`;

// extra for updating the state marketing dashboard

export const UPDATE_START_DATE_CONSULTANT_COUNT_TABLE = `UPDATE_START_DATE_CONSULTANT_COUNT_TABLE`
export const UPDATE_END_DATE_CONSULTANT_COUNT_TABLE = `UPDATE_END_DATE_CONSULTANT_COUNT_TABLE`
export const UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE = `UPDATE_END_DATE_OFFERS_FOR_MONTH_TABLE`;
export const UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE = `UPDATE_START_DATE_OFFERS_FOR_MONTH_TABLE`;

// Account 

export const ACCOUNT_RECEIVABLE_LIST = `ACCOUNT_RECEIVABLE_LIST`;
export const ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST = `ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST`;
export const ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION = `ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION`;
export const FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA = `FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA`;
export const FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS = 'FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS';
export const FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS = 'FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS';
export const FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS = 'FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS';
export const FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS = 'FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS';
export const FETCH_APPROVED_HOURS_LIST_SUCCESS = 'FETCH_APPROVED_HOURS_LIST_SUCCESS';
export const FETCH_TRANSACTION_LIST_SUCCESS = 'FETCH_TRANSACTION_LIST_SUCCESS';
export const FETCH_INVOICE_NUMBER_LIST_SUCCESS = 'FETCH_INVOICE_NUMBER_LIST_SUCCESS';
export const FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER = 'FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER';
export const FETCH_REIMBURSEMENT_LIST_SUCCESS = 'FETCH_REIMBURSEMENT_LIST_SUCCESS';
export const FETCH_ACCOUNT_SUPPORT_OFFER_LIST_SUCCESS = 'FETCH_ACCOUNT_SUPPORT_OFFER_LIST_SUCCESS';
export const FETCH_TRANSATION_LIST_TO_DOWNLOAD = 'FETCH_TRANSATION_LIST_TO_DOWNLOAD';




