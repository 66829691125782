import {fetchError, fetchStart,handleErrorResponse, fetchSuccess, showMessage, handleRemoveDisableSubmitButton} from "./Common";
import {userService} from "../services";
import {
  ADD_USER_EDUCATION,
  ADD_USER_EXPERIENCE,
  EDIT_USER_EDUCATION,
  EDIT_USER_EXPERIENCE,
  GET_EDUCATION,
  GET_EDUCATION_LIST,
  GET_EXPERIENCE,
  GET_EXPERIENCE_LIST,
  GET_PROFILE,
  USER_PASSWORD_UPDATE,
  USER_UPDATE,
  GET_ADDRESS_LIST,
  GET_ADDRESS,
  EDIT_USER_ADDRESS,
  ADD_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  GET_PRACTICE_LIST,
  EDIT_USER_PRACTICE,
  ADD_USER_PRACTICE,
  GET_PREVIOUS_CLIENT_LIST
} from "../../constants/ApiEndpoints";
import {
  ADD_EDUCATION_FAILURE,
  ADD_EDUCATION_SUCCESS,
  ADD_EXPERIENCE_FAILURE,
  ADD_EXPERIENCE_SUCCESS,
  ADD_ADDRESS_FAILURE,
  ADD_ADDRESS_SUCCESS,
  ADD_PRACTICE_FAILURE,
  ADD_PRACTICE_SUCCESS,
  FETCH_EDUCATION_LIST_FAILURE,
  FETCH_EDUCATION_LIST_SUCCESS,
  FETCH_EXPERIENCE_LIST_FAILURE,
  FETCH_EXPERIENCE_LIST_SUCCESS,
  FETCH_ADDRESS_LIST_FAILURE,
  FETCH_ADDRESS_LIST_SUCCESS,
  FETCH_PRACTICE_LIST_FAILURE,
  FETCH_PRACTICE_LIST_SUCCESS,
  FETCH_USER_EDUCATION_FAILURE,
  FETCH_USER_EDUCATION_SUCCESS,
  FETCH_USER_EXPERIENCE_FAILURE,
  FETCH_USER_EXPERIENCE_SUCCESS,
  FETCH_USER_ADDRESS_FAILURE,
  FETCH_USER_ADDRESS_SUCCESS,
  FETCH_USER_PRACTICE_FAILURE,
  FETCH_USER_PRACTICE_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS,
  FETCH_PREVIOUS_CLIENT_LIST_SUCCESS
} from "../../constants/ActionTypes"

// export const userInit = () => {
//   return {
//     type: USER_INIT,
//   }
// };


const fetchUserSuccess = (userData) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: userData
  }
};

const fetchUserFailure = () => {
  return {
    type: FETCH_USER_FAILURE
  }
};


export const getUser = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    let payload = {};
    userService.get(GET_PROFILE, payload).then((response) => {
      dispatch(fetchUserSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchUserFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

const editUserSuccess = (userData) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: userData
  };
};

const editUserFailed = () => {
  return {
    type: USER_UPDATE_FAILURE
  }

};


export const editUser = (values, userName, name) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = USER_UPDATE.replace('username', userName);
    userService.patch(endPoint, values)
      .then((response) => {
        dispatch(editUserSuccess(response));
        dispatch(showMessage(`Successfully updated ${name}.`));
        dispatch(fetchSuccess());
        dispatch(getUser())
      }).catch((error) => {
      dispatch(editUserFailed());
      dispatch(fetchError(error.data.error));
    });
  }
};


export const updatePassword = (values) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(USER_PASSWORD_UPDATE, values)
      .then((response) => {
        if ('detail' in response) {
          dispatch(fetchSuccess());
          dispatch(showMessage(response['detail']));
        } else {
          for (let key in response) {
            for (let errMsg in response[key]) {
              const errorMsg = key.toUpperCase().concat(": ").concat(errMsg);
              dispatch(fetchError(errorMsg));
            }
          }
        }
      }).catch((error) => {
      console.log(error, 'error');
      dispatch(editUserFailed());
      dispatch(fetchError(error.message));
    });
  }
};


export const updateUserPhoto = (values, username) => {
  const headers = {
    "Content-Type": "multipart/form-data"
   };
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = USER_UPDATE.replace(`username`, username);
    userService.upload(endPoint, values, headers)
      .then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          dispatch(showMessage(response['data']));
          dispatch(getUser());
        }
      }).catch((error) => {
      console.log(error, 'error');
      dispatch(editUserFailed());
      dispatch(fetchError(error.data.error));
    });
  }
};

// export const addAddress = (userId, addressData) => (dispatch) => {
//   dispatch({ type: ADD_USER_ADDRESS });

// };
// export const fetchUserAddress = (userId) => (dispatch) => {
//   dispatch({ type: GET_ADDRESS_LIST });
// };


const fetchEducationListSuccess = (userEducation) => {
  return {
    type: FETCH_EDUCATION_LIST_SUCCESS,
    payload: userEducation
  };
};

const fetchEducationListFailure = () => {
  return {
    type: FETCH_EDUCATION_LIST_FAILURE
  }

};

export const fetchEducationList = (filterBy = {id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_EDUCATION_LIST, filterBy).then((response) => {
      dispatch(fetchEducationListSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchEducationListFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};


const addEducationSuccess = (educationData) => {
  return {
    type: ADD_EDUCATION_SUCCESS,
    payload: educationData
  }
};

const addEducationFailed = () => {
  return {
    type: ADD_EDUCATION_FAILURE
  }
};

export const addEducation = (values, userId=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_USER_EDUCATION, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_education'))
        dispatch(addEducationSuccess(response));
        dispatch(handleErrorResponse({"educationAdd": "success"}))
        if(userId == null){
          dispatch(fetchEducationList());
        }else{
          dispatch(fetchEducationList({id: userId}));
        }
        dispatch(showMessage("Successfully added education."));
        dispatch(fetchSuccess());
      }).catch((error) => {
        dispatch(handleRemoveDisableSubmitButton('add_education'))
        dispatch(handleErrorResponse({"educationAdd": error}))
      dispatch(addEducationFailed());
      dispatch(fetchError(error.data.error));
    });
  }
};

const fetchEducationSuccess = (userEducation) => {
  return {
    type: FETCH_USER_EDUCATION_SUCCESS,
    payload: userEducation
  };
};

const fetchEducationFailure = (error= null) => {
  return {
    type: FETCH_USER_EDUCATION_FAILURE,
    payload: error
  }

};

export const fetchEducation = (filterBy = {id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_EDUCATION, filterBy).then((response) => {
      dispatch(fetchEducationSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchEducationFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

export const editEducation = (values, educationId, userId=null) => {
  return (dispatch) => {
    const endPoint = EDIT_USER_EDUCATION.replace('id', educationId);
    userService.put(endPoint, values)
      .then((response) => {
        dispatch(fetchEducationList({id:userId}));
        dispatch(showMessage("Successfully updated education."));
        dispatch(handleErrorResponse({"educationEdit": "success"}))
        dispatch(fetchSuccess());
      }).catch((error) => {
      dispatch(fetchEducationFailure());
      console.log('editErrrorrr', error)
      dispatch(handleErrorResponse({"educationEdit": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};


const fetchExperienceListSuccess = (experienceList) => {
  return {
    type: FETCH_EXPERIENCE_LIST_SUCCESS,
    payload: experienceList
  };
};

const fetchExperienceListFailure = () => {
  return {
    type: FETCH_EXPERIENCE_LIST_FAILURE
  }

};

export const fetchExperienceList = (filterBy = {id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_EXPERIENCE_LIST, filterBy).then((response) => {
      dispatch(fetchExperienceListSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchExperienceListFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

const addExperienceSuccess = (expData) => {
  return {
    type: ADD_EXPERIENCE_SUCCESS,
    payload: expData
  }
};

const addExperienceFailed = () => {
  return {
    type: ADD_EXPERIENCE_FAILURE
  }
};

export const addExperience = (values, userId=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_USER_EXPERIENCE, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_experience'))
        dispatch(addExperienceSuccess(response));
        dispatch(handleErrorResponse({"experienceAdd": "success"}))
        if(userId == null){
          dispatch(fetchExperienceList());
        }else{
          dispatch(fetchExperienceList({id: userId}));
        }
        dispatch(showMessage("Successfully added experience."));
        dispatch(fetchSuccess());
      }).catch((error) => {
        dispatch(handleRemoveDisableSubmitButton('add_experience'))
      dispatch(addExperienceFailed());
      dispatch(handleErrorResponse({"experienceAdd": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

const fetchExperienceSuccess = (userExperience) => {
  return {
    type: FETCH_USER_EXPERIENCE_SUCCESS,
    payload: userExperience
  };
};

const fetchExperienceFailure = () => {
  return {
    type: FETCH_USER_EXPERIENCE_FAILURE
  }

};

export const fetchExperience = (filterBy = {id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_EXPERIENCE, filterBy).then((response) => {
      dispatch(fetchExperienceSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchExperienceFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

export const editExperience = (values, expId, userId=null) => {
  return (dispatch) => {
    const endPoint = EDIT_USER_EXPERIENCE.replace('id', expId);
    userService.patch(endPoint, values)
      .then((response) => {
        dispatch(fetchExperienceList({id:userId}));
        dispatch(fetchExperienceSuccess(response));
         dispatch(handleErrorResponse({"experienceEdit": "success"}))
        dispatch(showMessage("Successfully updated experience."));
        dispatch(fetchSuccess());
      }).catch((error) => {
      console.log('erroreditexperience',error )
      dispatch(fetchExperienceFailure());
      dispatch(handleErrorResponse({"experienceEdit": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};




// address

const fetchAddressListSuccess = (addressList) => {
  return {
    type: FETCH_ADDRESS_LIST_SUCCESS,
    payload: addressList
  };
};

const fetchAddressListFailure = () => {
  return {
    type: FETCH_ADDRESS_LIST_FAILURE
  }

};

export const fetchAddressList = (filterBy) => {
  // if (filterBy === null) return; //if filterBy is null then it'll not run the function
  return (dispatch) => {
    dispatch(fetchStart());
    console.log('fetchAddewss', filterBy)
      const endPoint = GET_ADDRESS_LIST.replace('id/',`?user_id=${filterBy}`)
    userService.get(endPoint).then((response) => {
      dispatch(fetchAddressListSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchAddressListFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

const addAddressSuccess = (addressData) => {
  return {
    type: ADD_ADDRESS_SUCCESS,
    payload: addressData
  }
};

const addAddressFailed = () => {
  return {
    type: ADD_ADDRESS_FAILURE
  }
};

export const addAddress = (values, userId=null) => {
  console.log("adddAddtesssss",userId)
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_USER_ADDRESS, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_address'))
        dispatch(addAddressSuccess(response));
        dispatch(handleErrorResponse({"addressResponse": "success"}))
        if(userId == null){
          dispatch(fetchAddressList());
        }else{
          dispatch(fetchAddressList(userId));
        }
        dispatch(showMessage("Successfully added Address."));
        dispatch(fetchSuccess());
      }).catch((error) => {
        dispatch(handleRemoveDisableSubmitButton('add_address'))
      dispatch(addAddressFailed());
      dispatch(handleErrorResponse({"addressResponse": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

const fetchAddressSuccess = (userAddress) => {
  return {
    type: FETCH_USER_ADDRESS_SUCCESS,
    payload: userAddress
  };
};

const fetchAddressFailure = () => {
  return {
    type: FETCH_USER_ADDRESS_FAILURE
  }

};

export const fetchAddress = (filterBy = {id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ADDRESS, filterBy).then((response) => {
      dispatch(fetchAddressSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchAddressFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

export const editAddress = (values, addId, userId=null) => {
  return (dispatch) => {
    var endPoint = EDIT_USER_ADDRESS.replace('id/',`?user_id=${userId}`)
    endPoint = endPoint.replace('addId/',`${addId}/`)
    userService.patch(endPoint, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_address'))
        dispatch(fetchAddressList(userId));
        dispatch(fetchAddressSuccess(response));
        dispatch(showMessage("Successfully updated address."));
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"addressResponse": "success"}))
      }).catch((error) => {
        dispatch(fetchAddressFailure());
        dispatch(handleRemoveDisableSubmitButton('add_address'))
      dispatch(handleErrorResponse({"addressResponse": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

export const deleteAddress = ( delId, userId=null) => {
  return (dispatch) => {
    var endPoint = DELETE_USER_ADDRESS.replace('id/',`?user_id=${userId}`)
    endPoint = endPoint.replace('delId/',`${delId}/`)
    userService.destroy(endPoint)
      .then((response) => {
        dispatch(fetchAddressList(userId));
        dispatch(fetchAddressSuccess(response));
        dispatch(showMessage("Successfully deleted address."));
        dispatch(fetchSuccess());
      }).catch((error) => {
      dispatch(fetchAddressFailure());
      dispatch(fetchError(error.data.error));
    });
  }
};

// PRACTICES  


const fetchPracticeListSuccess = (practiceList) => {
  return {
    type: FETCH_PRACTICE_LIST_SUCCESS,
    payload: practiceList
  };
};

const fetchPracticeListFailure = () => {
  return {
    type: FETCH_PRACTICE_LIST_FAILURE
  }

};

export const fetchPracticeList = (filterBy) => {
  // if (filterBy === null) return; //if filterBy is null then it'll not run the function
  return (dispatch) => {
    dispatch(fetchStart());
    console.log('fetchAddewss', filterBy)
      const endPoint = GET_PRACTICE_LIST.replace('id/',`${filterBy}/`)
    userService.get(endPoint).then((response) => {
      dispatch(fetchPracticeListSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchPracticeListFailure());
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};

const addPracticeSuccess = (practiceData) => {
  return {
    type: ADD_PRACTICE_SUCCESS,
    payload: practiceData
  }
};

const addPracticeFailed = () => {
  return {
    type: ADD_PRACTICE_FAILURE
  }
};

export const addPractice = (values, userId=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = ADD_USER_PRACTICE.replace('id/',`${userId}/`)
    userService.post(endPoint, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_edit_practice'))
        dispatch(fetchPracticeList(userId));
        dispatch(fetchPracticeSuccess(response));
        dispatch(showMessage("Successfully added Practice."));
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"practiceResponse": "success"}))
      }).catch((error) => {
        dispatch(handleRemoveDisableSubmitButton('add_edit_practice'))
      dispatch(addPracticeFailed());
      dispatch(handleErrorResponse({"practiceResponse": error}))
      dispatch(fetchError(error.data.errors?error.data.errors.error?error.data.errors.error:'Error adding practices':'Error Adding Practices'));
     // getting response error.data.errors.error for adding practices from admin's account
    });
  }
};

const fetchPracticeSuccess = (userPractice) => {
  return {
    type: FETCH_USER_PRACTICE_SUCCESS,
    payload: userPractice
  };
};

export const editPractice = (values, pracId, userId=null) => {
  return (dispatch) => {
    var endPoint = EDIT_USER_PRACTICE.replace('id/',`${userId}/`)
    endPoint = endPoint.replace('pracId/',`${pracId}/`)
    userService.put(endPoint, values)
      .then((response) => {
        dispatch(handleRemoveDisableSubmitButton('add_edit_practice'))
        dispatch(fetchPracticeList(userId));
        dispatch(fetchPracticeSuccess(response));
        dispatch(showMessage("Successfully updated practice."));
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"practiceResponse": "success"}))
      }).catch((error) => {
        dispatch(handleRemoveDisableSubmitButton('add_edit_practice'))
      dispatch(handleErrorResponse({"practiceResponse": error}))
      dispatch(fetchAddressFailure());
      dispatch(fetchError(error.data.error));
      dispatch(fetchError(error.data.errors?error.data.errors.error?error.data.errors.error:'Error adding practices':'Error Adding Practices'));
    });
  }
};

const fetchPreviousClientListSuccess = (response) => {
  return {
    type: FETCH_PREVIOUS_CLIENT_LIST_SUCCESS,
    payload: response
  };
};

export const fetchPreviousClientList = (filterBy={id:null}) => {
  // if (filterBy === null) return; //if filterBy is null then it'll not run the function
  return (dispatch) => {
    dispatch(fetchStart());
    console.log('fetch prev Client', filterBy)
      const endPoint = GET_PREVIOUS_CLIENT_LIST.replace('id/',`${filterBy.id}/`)
    userService.get(endPoint).then((response) => {
      dispatch(fetchPreviousClientListSuccess(response));
      dispatch(fetchSuccess());
      return response;
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      console.log("Error****:", error.data);
    });
  }
};