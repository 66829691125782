import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_SCREENER_CALENDAR_FAILURE,
  ADD_SCREENER_CALENDAR_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  DRAG_FAILED,
  DRAG_HAPPENED,
  EDIT_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_SUCCESS,
  EMPLOYEE_INIT,
  FETCH_BAR_DATA_FAILURE,
  FETCH_BAR_DATA_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DONUT_DATA_FAILURE,
  FETCH_DONUT_DATA_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_LIST_FAILURE,
  FETCH_EMPLOYEE_LIST_SUCCESS,
  FETCH_SUPPORT_ID_LIST_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  FETCH_HIRED_DATA_FAILURE,
  FETCH_HIRED_DATA_SUCCESS,
  FETCH_PIE_DATA_FAILURE,
  FETCH_PIE_DATA_SUCCESS,
  FETCH_RATIO_DATA_SUCCESS,
  FETCH_RECENT_FAILURE,
  FETCH_RECENT_SUCCESS,
  FETCH_SCHEDULED_SCREENINGS_FAILURE,
  FETCH_SCHEDULED_SCREENINGS_SUCCESS,
  FETCH_SCREENER_FAILURE,
  FETCH_SCREENER_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_SUCCESS,
  FETCH_TASKS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  GET_DEPARTMENTS_SUCCESS,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYERS_FAILURE,
  GET_EMPLOYERS_SUCCESS,
  GET_MANAGERS_FAILURE,
  GET_MANAGERS_SUCCESS,
  GET_MARKETERS_FAILURE,
  GET_MARKETERS_SUCCESS,
  SELECT_SCREENER_FAILURE,
  SELECT_SCREENER_SUCCESS,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAILURE,
  FETCH_RECRUITERS_SUCCESS,
  FETCH_RECRUITERS_FAILURE,
  GET_MARKETING_LEAD_SUCCESS,
  GET_MARKETING_LEAD_FAILURE,
  GET_MARKETING_SUBORDINATES_SUCCESS,
  GET_MARKETING_SUBORDINATES_FAILURE,
  FETCH_MARKETING_STANDUP_LIST_SUCCESS,
  GET_RECRUITMENT_LEAD_FAILURE,
  GET_RECRUITMENT_LEAD_SUCCESS,
  FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS,
  FETCH_MARKETER_LIST_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  dashboard: null,
  recentRecruits: null,
  status: null,
  screeners: null,
  employers: null,
  barData: null,
  pieData: null,
  donutData: null,
  hiredData: null,
  ratioData: null,
  screenings: null,
  totalScreenings: 0,
  addEmployee: false,
  employee: null,
  employees: null,
  totalEmployees: 0,
  managers: null,
  marketers: null,
  departments: null,
  events: null,
  selectedScreener: '',
  taskList:null,
  employeeListData: null,
  marketingLeads: null,
  recruitmentLeads:null,
  marketingSubordinates: null,
  supportIdList: null,
  allMarketerList: null,
  marketingStandupList: [],
  recruitersAddedConsultant: null,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEE_INIT:{
      return {
        employee: null
      }
    }
    case FETCH_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: action.payload
      }
    }
    case FETCH_DASHBOARD_FAILURE: {
      return {
        ...state,
        dashboard: null
      }
    }
    case FETCH_BAR_DATA_SUCCESS: {
      return {
        ...state,
        barData: action.payload
      }
    }
    case FETCH_BAR_DATA_FAILURE: {
      return {
        ...state,
        barData: null
      }
    }
    case FETCH_PIE_DATA_SUCCESS: {
      return {
        ...state,
        pieData: action.payload
      }
    }
    case FETCH_PIE_DATA_FAILURE: {
      return {
        ...state,
        pieData: null
      }
    }
    case FETCH_DONUT_DATA_SUCCESS: {
      return {
        ...state,
        donutData: action.payload
      }
    }
    case FETCH_DONUT_DATA_FAILURE: {
      return {
        ...state,
        donutData: null
      }
    }
    case FETCH_HIRED_DATA_SUCCESS: {
      return {
        ...state,
        hiredData: action.payload
      }
    }
    case FETCH_HIRED_DATA_FAILURE: {
      return {
        ...state,
        hiredData: null
      }
    }
    case FETCH_RATIO_DATA_SUCCESS: {
      return {
        ...state,
        ratioData: action.payload
      }
    }
    case FETCH_TASKS_SUCCESS: {
      return {
        ...state,
        taskList: action.payload.results
      }
    }
    case FETCH_RECENT_SUCCESS: {
      return {
        ...state,
        recentRecruits: action.payload
      }
    }
    case FETCH_RECENT_FAILURE: {
      return {
        ...state,
        recentRecruits: null
      }
    }

    case FETCH_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employees: action.payload.results,
        totalEmployees: action.payload.count
      }
    }
    case FETCH_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employeeListData: action.payload,
      }
    }
    case FETCH_RECRUITERS_SUCCESS: {
      return {
        ...state,
        recruiters: action.payload.results,
      }
    }
    case FETCH_EMPLOYEE_FAILURE: {
      return {
        ...state,
        employees: [],
        totalEmployees: 0
      }
    }
    case FETCH_EMPLOYEE_LIST_FAILURE: {
      return {
        ...state,
        employeeListData:[]
      }
    }
    case FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.payload
      }
    }
    case FETCH_STATUS_FAILURE: {
      return {
        ...state,
        status: []
      }
    }
    case DRAG_HAPPENED: {
      return {
        ...state
      };
    }
    case DRAG_FAILED: {
      return {
        ...state,
        status: action.payload
      }
    }

    case FETCH_SCREENER_SUCCESS: {
      return {
        ...state,
        screeners: action.payload
      }
    }
    case FETCH_SCREENER_FAILURE: {
      return {
        ...state,
        screeners: null
      }
    }
    case GET_MANAGERS_SUCCESS: {
      return {
        ...state,
        managers: action.payload
      }
    }
    case GET_MARKETERS_SUCCESS: {
      return {
        ...state,
        marketers: action.payload
      }
    }
    case GET_MARKETING_SUBORDINATES_SUCCESS: {
      return {
        ...state,
        marketingSubordinates: action.payload
      }
    }
    case GET_MARKETING_SUBORDINATES_FAILURE: {
      return {
        ...state,
        marketingSubordinates: null
      }
    }

    case GET_MARKETING_LEAD_SUCCESS: {
      return {
        ...state,
        marketingLeads: action.payload
      }
    }

    case GET_EMPLOYERS_SUCCESS: {
      return {
        ...state,
        employers: action.payload
      }
    }
    case GET_EMPLOYERS_FAILURE: {
      return {
        ...state,
        employers: null
      }
    }
    case GET_MARKETERS_FAILURE: {
      return {
        ...state,
        marketers: null
      }
    }
    case GET_MARKETING_LEAD_FAILURE: {
      return {
        ...state,
        marketingLeads: null
      }
    }
    case GET_RECRUITMENT_LEAD_SUCCESS: {
      return {
        ...state,
        recruitmentLeads: action.payload
      }
    }
    case GET_RECRUITMENT_LEAD_FAILURE: {
      return {
        ...state,
        recruitmentLeads: null
      }
    }
    case GET_MANAGERS_FAILURE: {
      return {
        ...state,
        managers: null
      }
    }
    case GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.payload
      }
    }
    case GET_DEPARTMENTS_FAILURE: {
      return {
        ...state,
        departments: null
      }
    }
    case SELECT_SCREENER_SUCCESS: {
      return {
        ...state,
        selectedScreener: action.payload
      }
    }
    case SELECT_SCREENER_FAILURE: {
      return {
        ...state,
        selectedScreener: null
      }
    }
    case GET_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: action.payload
      }
    }
    case GET_EMPLOYEE_FAILURE: {
      return {
        ...state,
        employee: null
      }
    }
    case FETCH_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload.data,
      }
    }
    case FETCH_EVENTS_FAILURE: {
      return {
        ...state,
        events: null
      }
    }
    case FETCH_SCHEDULED_SCREENINGS_SUCCESS: {
      return {
        ...state,
        screenings: action.payload.results,
        totalScreenings: action.payload.count
      }
    }
    case FETCH_SCHEDULED_SCREENINGS_FAILURE: {
      return {
        ...state,
        screenings: null,
        totalScreenings: 0
      }
    }
    case ADD_SCREENER_CALENDAR_SUCCESS: {
      const updatedEvents = [...state.events, action.payload];
      return {
        ...state,
        events: updatedEvents
      }

    }
    case ADD_SCREENER_CALENDAR_FAILURE: {
      return {
        ...state
      }
    }

    case DELETE_EVENT_SUCCESS: {
      const updatedEvents = [...state.events.filter((item) => item.details.id !== action.payload.id)];
      // sessionStorage.setItem('events', JSON.stringify(updatedEvents));
      return {
        ...state,
        events: updatedEvents
      }

    }
    case DELETE_EVENT_FAILURE: {
      return {
        ...state
      }
    }

    case ADD_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: action.payload,
        addEmployee: false
      }
    }
    case EDIT_EMPLOYEE_FAILURE:
    case ADD_EMPLOYEE_FAILURE: {
      return {
        ...state,
        employee: null,
        message: action.payload,
        addEmployee: false
      }
    }
    case EDIT_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: action.payload,
        addEmployee: false
      }
    }
    case GET_LEVELS_SUCCESS: {
      return {
        ...state,
        levels: action.payload
      }
    }
    case GET_LEVELS_FAILURE: {
      return {
        ...state,
        levels: null
      }
    }
    case FETCH_SUPPORT_ID_LIST_SUCCESS: {
      return {
        ...state,
        supportIdList: action.payload
      }
    }
    case FETCH_MARKETER_LIST_SUCCESS: {
      return {
        ...state,
        allMarketerList: action.payload
      }
    }
    case FETCH_MARKETING_STANDUP_LIST_SUCCESS: {
      return {
        ...state,
        marketingStandupList: action.payload
      }
    }
    case FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS: {
      return {
        ...state,
        recruitersAddedConsultant: action.payload
      }
    }
    default:
      return state;
  }
}
