import {
    ACCOUNT_RECEIVABLE_LIST,
    ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST,
    ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION,
    FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS,
    FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS,
    FETCH_ACCOUNT_SUPPORT_OFFER_LIST_SUCCESS,
    FETCH_APPROVED_HOURS_LIST_SUCCESS,
    FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS,
    FETCH_INVOICE_NUMBER_LIST_SUCCESS,
    FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS,
    FETCH_REIMBURSEMENT_LIST_SUCCESS,
    FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA,
    FETCH_TRANSACTION_LIST_SUCCESS,
    FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER,
    FETCH_TRANSATION_LIST_TO_DOWNLOAD,
  } from "../../constants/ActionTypes";
  
  import {fetchError, fetchStart, fetchSuccess, handleErrorResponse, handleRemoveDisableSubmitButton, showMessage} from "./Common";
  import { ADD_REIMBURSEMENT, DELETE_TRANSACTION_LIST, EDIT_ACCOUNT_RECEIVABLE_DATA, EDIT_ACCOUNTS_OFFERS, Edit_REIMBURSEMENT, GENERATE_PAYROLL, GET_ACCOUNT_CONSULTANT, GET_ACCOUNT_CONSULTANT_LIST, GET_ACCOUNT_CONSULTANT_OFFERS, GET_ACCOUNT_CONSULTANT_OFFERS_ASSIGNED_PAYEE_LIST, GET_ACCOUNT_RECEIVABLE_LIST, GET_ACCOUNT_SUPPORT_OFFERS, GET_ALL_OFFERS_EXCLUDING_JOINGING_PENDING, GET_APPROVED_HOURS, GET_CONSULTANT_OFFERS, GET_CONSULTANT_ON_OFFERS_LIST, GET_INVOICE_NUMBER_LIST, GET_SIGNLE_ACCOUNT_RECEIVABLE_DATA, GET_TRANSACTION_LIST, GET_TRANSATION_LIST_FROM_INVOICE_NUMBER, GET_TRANSATION_LIST_TO_DOWNLOAD, UPDATE_TRANSACTION_LIST } from "../../constants/ApiEndpoints";
  import {userService} from "../services";
import { message } from "antd";

const fetchAccountReceivableListSuccess = (response) => {
    return{
        type: ACCOUNT_RECEIVABLE_LIST,
        payload: response
    }
}

export const fetchAccountReceivableList = (filterBy={page:1 }) => {
    return (dispatch) => {
        dispatch(fetchStart());
        userService.get(GET_ACCOUNT_RECEIVABLE_LIST,filterBy).then((response) => {
        if (response) {
            dispatch(fetchAccountReceivableListSuccess(response));
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
        dispatch(fetchError(error.data.error));
        });
    }
};

export const AddAccountReceivable = (filterBy={value:null ,currentPage:1}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        userService.post(GET_ACCOUNT_RECEIVABLE_LIST,filterBy.value).then((response) => {
        if (response) {
            dispatch(handleRemoveDisableSubmitButton('add_account_receivable'))
            dispatch(handleErrorResponse({"add_account_receivable": "success"}))
            dispatch(fetchAccountReceivableList({page: filterBy.currentPage}));
            dispatch(fetchSuccess());
            dispatch(showMessage('Successful!'))
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
            dispatch(handleRemoveDisableSubmitButton('add_account_receivable'))
            dispatch(handleErrorResponse({"add_account_receivable": error}))
        });
    }
};

const fetchAllOffersOtherThenJoiningPendingOffersSuccess = (response) => {
    return{
        type: ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST,
        payload: response
    }
}

const fetchAllOffersOtherThenJoiningPendingNoPagination = (response) => {
    return{
        type: ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION,
        payload: response
    }
}

export const fetchAllOffersExcludingJoingingPending = (filterBy={page:1, pagination:true}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        userService.get(GET_ALL_OFFERS_EXCLUDING_JOINGING_PENDING,filterBy).then((response) => {
        if (response) {
            if(filterBy.pagination === false){
                dispatch(fetchAllOffersOtherThenJoiningPendingNoPagination(response));
            }else{
                dispatch(fetchAllOffersOtherThenJoiningPendingOffersSuccess(response));
            }
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
        dispatch(fetchError(error.data.error));
        });
    }
};

const fetchSingleAccountReceivableDataSuccess = (response) => {
    return{
        type: FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA,
        payload: response
    }
}


export const fetchSingleAccountReceivableData = (filterBy={accountReceivableId: null}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        const andPoint = GET_SIGNLE_ACCOUNT_RECEIVABLE_DATA.replace('receivablesId',filterBy.accountReceivableId)
        userService.get(andPoint).then((response) => {
        if (response) {
            dispatch(fetchSingleAccountReceivableDataSuccess(response));
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
        dispatch(fetchError(error.data.error));
        });
    }
};

export const editAccountReceivable = (filterBy={accountReceivableId: null, value: null, currentPage:1}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        const andPoint = EDIT_ACCOUNT_RECEIVABLE_DATA.replace('receivablesId',filterBy.accountReceivableId)
        userService.patch(andPoint,filterBy.value).then((response) => {
        if (response) {
            dispatch(fetchAccountReceivableList({page:filterBy.currentPage}))
            dispatch(handleRemoveDisableSubmitButton('edit_account_receivable'))
            dispatch(showMessage('Successful!'))
            dispatch(handleErrorResponse({"edit_account_receivable": 'success'}))
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
            dispatch(handleRemoveDisableSubmitButton('edit_account_receivable'))
            dispatch(handleErrorResponse({"edit_account_receivable": error}))
        });
    }
};

export const fetchAccountConsultantSuccess = (response) => {
    return {
      type: FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS,
      payload: response
    }
};
  
export const fetchAccountConsultantList = (filterBy={page:1}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        // GET_ACCOUNT_CONSULTANT_LIST
        userService.get(GET_ACCOUNT_CONSULTANT ,filterBy).then((response) => {
            if (response) {
                dispatch(fetchAccountConsultantSuccess(response));
                dispatch(fetchSuccess());
            }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    }
}

export const fetchConsultantOnOffersSuccess = (response) => {
    return {
      type: FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS,
      payload: response
    }
};

export const fetchConsultantOnOffersList = (filterBy={consultant: null}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        userService.get(GET_CONSULTANT_ON_OFFERS_LIST ,filterBy).then((response) => {
            if (response) {
                dispatch(fetchConsultantOnOffersSuccess(response));
                dispatch(fetchSuccess());
            }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    }
}

export const fetchAccountConsultantObjectSuccess = (response) => {
    return {
      type: FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS,
      payload: response
    }
};
  
export const fetchAccountConsultantObject = (filterBy={consultant_id:null}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        const endPoint = GET_ACCOUNT_CONSULTANT_LIST.concat(`?consultant_id=${filterBy.consultant_id}`)
        userService.get(endPoint).then((response) => {
            if (response) {
                dispatch(fetchAccountConsultantObjectSuccess(response));
                dispatch(fetchSuccess());
            }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    }
}

export const fetchAccountConsultantOffersListSuccess = (offers) => {
    return {
      type: FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS,
      payload: offers
    }
  };
  
  export const fetchAccountConsultantOffersList = (filterBy={page:1, consultant:null}) => {  // this will fetch the ongoing offers of the consultant
    return(dispatch) => {
      dispatch(fetchStart());
      userService.get(GET_ACCOUNT_CONSULTANT_OFFERS, filterBy).then((response) => {
          if (response) {
              dispatch(fetchAccountConsultantOffersListSuccess(response))
            dispatch(fetchSuccess());
          }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    };
  };


export const fetchOngoingAccountOffersListSuccess = (offers) => {
    return {
        type: FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS,
        payload: offers
    }
};
export const fetchClosedAccountOffersListSuccess = (offers) => {
    return {
        type: FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS,
        payload: offers
    }
};

export const fetchAccountsOffersList = (filterBy={ page:1, status:null }) => {  // this will fetch the ongoing offers of the consultant
    return(dispatch) => {
        dispatch(fetchStart());
        userService.get(GET_ALL_OFFERS_EXCLUDING_JOINGING_PENDING, filterBy).then((response) => {
            if (response) {
                if(filterBy.status === 'ongoing'){
                    dispatch(fetchOngoingAccountOffersListSuccess(response))
                }
                if(filterBy.status === 'closed'){
                    dispatch(fetchClosedAccountOffersListSuccess(response))
                }
                dispatch(fetchSuccess());
            }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    };
};

export const editAccountsOffer = (filterBy={offerId: null, value: null, tableType: null, currentFilter: null,
  currentPage: null
}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_ACCOUNTS_OFFERS.replace('offerId',filterBy.offerId)
    userService.patch(apiEndpoint, filterBy.value).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        dispatch(fetchAccountsOffersList({page: filterBy.currentPage, status: filterBy.tableType, 
          ...(filterBy.currentFilter && { ...filterBy.currentFilter })
        }))
        dispatch(handleErrorResponse({"edit_account_offer": "success"}))
      }
    }).catch(function (error) {
      dispatch(handleErrorResponse({"edit_account_offer": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAccountOfferConsultantPayeesListSuccess = (response) => {
    return {
        type: FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS,
        payload: response
    }
};

export const fetchAccountOfferConsultantPayeesList = (filterBy={offerId: null}) => {
    return (dispatch) => {
      dispatch(fetchStart());
      const apiEndpoint = GET_ACCOUNT_CONSULTANT_OFFERS_ASSIGNED_PAYEE_LIST.concat(`&offer=${filterBy.offerId}`).concat('&pagination=false')
      userService.get(apiEndpoint).then((response) => {
        if (response) {
          dispatch(fetchAccountOfferConsultantPayeesListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
    }
  };

export const resetAccountOfferConsultantPayeesList = () => {
    return (dispatch) => {
        dispatch(fetchAccountOfferConsultantPayeesListSuccess(null));
    }
}

export const GeneratePayroll = (filterBy={value:null ,currentPage:1}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        userService.post(GENERATE_PAYROLL,filterBy.value).then((response) => {
        if (response) {
            dispatch(fetchSuccess());
            message.success("Successful !")
            dispatch(handleRemoveDisableSubmitButton('generate_payroll'))
            dispatch(handleErrorResponse({"generate_payroll": "success"}))
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
            dispatch(handleErrorResponse({"generate_payroll": error}))
            dispatch(handleRemoveDisableSubmitButton('generate_payroll'))
        });
    }
};

export const fetchAccountHoursListSuccess = (response) => {
    return {
        type: FETCH_APPROVED_HOURS_LIST_SUCCESS,
        payload: response
    }
};

export const fetchApprovedHours = (filterBy={offerId: null, start_date: null, end_date: null}) => {
    return (dispatch) => {
      dispatch(fetchStart());
      const apiEndpoint = GET_APPROVED_HOURS.replace('offerId',filterBy.offerId)
      userService.get(apiEndpoint,filterBy).then((response) => {
        if (response) {
          dispatch(fetchAccountHoursListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
    }
  };


export const fetchAccountTransactionListSuccess = (response) => {
    return {
        type: FETCH_TRANSACTION_LIST_SUCCESS,
        payload: response
    }
};

export const fetchTransactionList = (filterBy={page:1, ap_type: null, type: null, consultant_payee: null, 
    support_payee: null, invoice_number: null, start_date: null, end_date: null }) => {
    return (dispatch) => {
      dispatch(fetchStart());
      const apiEndpoint = GET_TRANSACTION_LIST
      userService.get(apiEndpoint,filterBy).then((response) => {
        if (response) {
          dispatch(fetchAccountTransactionListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
    }
  };

export const editTransaction = (filterBy={transactionId:null, value:null, currentPage:1, pageSize:null, filters:{}}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        const {filters} = filterBy
        const andPoint = UPDATE_TRANSACTION_LIST.replace('transactionId',filterBy.transactionId)
        // userService.patch(andPoint,filterBy.value).then((response) => {
            userService.put(andPoint,filterBy.value).then((response) => {
        if (response) {
            dispatch(fetchTransactionList({page: filterBy.currentPage ,ap_type: filters.ap_type ,type: filters.type,
                payee: filters.payee ,invoice_number: filters.invoice_number ,start_date: filters.start_date,
                end_date: filters.end_date 
            }));
            dispatch(handleRemoveDisableSubmitButton('edit_transaction'))
            dispatch(handleErrorResponse({"edit_transaction": 'success'}))
            dispatch(showMessage('Successful!'))
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
            dispatch(handleRemoveDisableSubmitButton('edit_transaction'))
            dispatch(handleErrorResponse({"edit_transaction": error}))
        });
    }
};

export const deleteTransaction = (filterBy={transactionId:null, value:null, currentPage:1, pageSize:null, filters:{}}) => {
    return (dispatch) => {
        dispatch(fetchStart());
        const {filters} = filterBy
        const andPoint = DELETE_TRANSACTION_LIST.replace('transactionId',filterBy.transactionId)
        userService.destroy(andPoint).then((response) => {
        if (response) {
            dispatch(fetchTransactionList({page: filterBy.currentPage ,ap_type: filters.ap_type ,type: filters.type,
                payee: filters.payee ,invoice_number: filters.invoice_number ,start_date: filters.start_date,
                end_date: filters.end_date 
            }));
            dispatch(showMessage('Successful!'))
            dispatch(fetchSuccess());
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
        });
    }
};


export const fetchInvoiceListSuccess = (response) => {
    return {
        type: FETCH_INVOICE_NUMBER_LIST_SUCCESS,
        payload: response
    }
};

export const fetchInvoiceNumberList = () => {
    return (dispatch) => {
      dispatch(fetchStart());
      const apiEndpoint = GET_INVOICE_NUMBER_LIST
      userService.get(apiEndpoint).then((response) => {
        if (response) {
          dispatch(fetchInvoiceListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
    }
  };

export const fetchTransactionListByInvoiceNumberSuccess = (response) => {
    return {
        type: FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER,
        payload: response
    }
};

export const fetchTransactionListByInvoiceNumber = (filterBy={invoice_number:null, ap_type:null}) => {
    return (dispatch) => {
      dispatch(fetchStart());
      const apiEndpoint = (filterBy.ap_type === undefined || filterBy.ap_type === null) ? GET_TRANSATION_LIST_FROM_INVOICE_NUMBER.replace('invoice_number',filterBy.invoice_number) 
      :
      GET_TRANSATION_LIST_FROM_INVOICE_NUMBER.replace('invoice_number',filterBy.invoice_number).concat('?ap_type=',filterBy.ap_type) 
      userService.get(apiEndpoint).then((response) => {
        if (response) {
          dispatch(fetchTransactionListByInvoiceNumberSuccess(response));
          dispatch(handleRemoveDisableSubmitButton('download_invoice_pdf'))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(handleRemoveDisableSubmitButton('download_invoice_pdf'))
        dispatch(fetchError(error.data.error));
      });
    }
};

export const fetchTransactionListToDownloadSuccess = (response) => {
    return {
        type: FETCH_TRANSATION_LIST_TO_DOWNLOAD,
        payload: response
    }
};

export const fetchTransactionListToDownload = (filterBy={download:true}) => {
    return (dispatch) => {
      dispatch(fetchStart());
    const apiEndpoint = GET_TRANSATION_LIST_TO_DOWNLOAD
      userService.get(apiEndpoint, filterBy).then((response) => {
        if (response) {
          dispatch(fetchTransactionListToDownloadSuccess(response));
          dispatch(handleRemoveDisableSubmitButton('download_invoice_pdf'))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(handleRemoveDisableSubmitButton('download_invoice_pdf'))
        dispatch(fetchError(error.data.error));
      });
    }
};

export const addReimbursement = (filterBy={value:null ,currentPage:1}) => {
    const headers = {
        "Content-Type": "multipart/form-data"
       };
    return (dispatch) => {
        dispatch(fetchStart());
        userService.post(ADD_REIMBURSEMENT, filterBy.value, headers).then((response) => {
        if (response) {
            dispatch(handleRemoveDisableSubmitButton('add_reimbursement'))
            dispatch(handleErrorResponse({"add_reimbursement": "success"}))
            dispatch(fetchReimbursementList({page: filterBy.currentPage}));
            dispatch(fetchSuccess());
            dispatch(showMessage('Successful!'))
        }
        }).catch(function (error) {
            dispatch(fetchError(error.data.error));
            dispatch(handleRemoveDisableSubmitButton('add_reimbursement'))
            dispatch(handleErrorResponse({"add_reimbursement": error}))
        });
    }
};

export const fetchReimbursementSuccess = (response) => {
    return {
        type: FETCH_REIMBURSEMENT_LIST_SUCCESS,
        payload: response
    }
};

export const fetchReimbursementList = (filterBy={page:1}) => {
    return (dispatch) => {
      dispatch(fetchStart());
      userService.get(ADD_REIMBURSEMENT,filterBy).then((response) => {
        if (response) {
          dispatch(fetchReimbursementSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
    }
  };

export const editReimbursement = (filterBy={value:null ,reimbursementId:null, currentPage:1}) => {
    const headers = { "Content-Type": "multipart/form-data" };
    return (dispatch) => {
      dispatch(fetchStart());
    var endPoint = Edit_REIMBURSEMENT.replace('reimbursementId',filterBy.reimbursementId)
      userService.patch(endPoint, filterBy.value, headers).then((response)=>{
        if (response) {
          dispatch(fetchReimbursementList({page: filterBy.currentPage}));
          dispatch(handleRemoveDisableSubmitButton('edit_reimbursement'))
          dispatch(handleErrorResponse({"edit_reimbursement": "success"}))
          dispatch(fetchSuccess());
        }
      }).catch((error)=>{
        dispatch(fetchError(error.data.error));
        dispatch(handleRemoveDisableSubmitButton('edit_reimbursement'))
        dispatch(handleErrorResponse({"edit_reimbursement": error}))
      })
    }
}
  
export const fetchAccountSupportOffersListSuccess = (offers) => {
    return {
      type: FETCH_ACCOUNT_SUPPORT_OFFER_LIST_SUCCESS,
      payload: offers
    }
};
  
export const fetchAccountSupportOffersList = (filterBy={page:1, support_id:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    let endPoint = GET_ACCOUNT_SUPPORT_OFFERS.replace('support_id',filterBy.support_id)
    delete filterBy.support_id
    userService.get(endPoint, filterBy).then((response) => {
      if (response) {
        dispatch(fetchAccountSupportOffersListSuccess(response))
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
        dispatch(fetchError(error.data.error));
    });
};
};

  