import {
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_OFFER_SUCCESS,
    FETCH_OFFER_FAILED,
    FETCH_TEAM_OFFER_FAILED,
    FETCH_TEAM_OFFER_SUCCESS,
    FETCH_MONTHLY_OFFER_SUCCESS,
    FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS,
    FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS,
    FETCH_SUPPORT_PERSON_LIST_SUCCESS,
    FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS,
    FETCH_ENGINEER_OFFER_LIST_SUCCESS,
    FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS,
    FETCH_PROJECT_COORDINATORS_LIST_SUCCESS,
    FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS,
    FETCH_TIMESHEET_CONFIG_LIST_SUCCESS,
    FETCH_TIMESHEET_HOURS_LIST_SUCCESS,
    FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS,
    FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS,
    FETCH_CONSULTANT_OFFER_LIST_SUCCESS,
    FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS,
    FETCH_CONTRACT_VERIFIED_LIST_SUCCESS,
    FETCH_SINGLE_ENGINEER_OFFER_SUCCESS,
    FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    offers: null,
    totalOffers: 0,
    teamOffers:null,
    totalTeamOffers:0,
    monthWiseOffers:null,
    joiningPendingOffersList:null,
    totalJoiningPendingOffers:0,
    projectCoordinatorOngoingOffersList:null,
    totalProjectCoordinatorOngoingOffers:0,
    supportPersonList:null,
    totalSupportPersonList:0,
    supportPersonListWithNoPagination:null,
    engineerOffersList:null,
    totalEngineerOffers:0,
    engineerJoiningPendingOffersList:null,
    totalEngineerJoiningPendingOffers:0,
    projectCoordinatorsList:null,
    totalProjectCoordinatorsListList:0,
    timeSheetConfigList:null,
    timeSheetHoursList:null,
    timeSheetAttachmentList:null,
    vendorTimeSheetAttachmentList:null,
    contractTeamOffersList:null,
    totalContractTeamOffersCount:0,
    contractTeamDoucumentList:null,
    individualEngineerOffersList:null,
    totalIndividualEngineerOffersCount:0,
    supportPersonOptionList:null
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

      case FETCH_OFFER_SUCCESS: {
        return {
          ...state,
          offers: action.payload.results,
          totalOffers: action.payload.count,
        }
      }
      case FETCH_OFFER_FAILED: {
        return {
          ...state,
          offers: null,
          totalOffers:0,
        }
      }
      case FETCH_TEAM_OFFER_SUCCESS: {
        return {
          ...state,
          teamOffers: action.payload.results,
          totalTeamOffers: action.payload.count,
        }
      }
      case FETCH_TEAM_OFFER_FAILED: {
        return {
          ...state,
          teamOffers: null,
          totalTeamOffers:0,
        }
      }
      case FETCH_MONTHLY_OFFER_SUCCESS: {
        return {
          ...state,
          monthWiseOffers: action.payload,
        }
      }
      case FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS: {
        return {
          ...state,
          joiningPendingOffersList: action.payload.results,
          totalJoiningPendingOffers: action.payload.count,
        }
      }
      case FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS: {
        return {
          ...state,
          projectCoordinatorOngoingOffersList: action.payload.results,
          totalprojectCoordinatorOngoingOffers: action.payload.count,
        }
      }
      case FETCH_SUPPORT_PERSON_LIST_SUCCESS: {
        return {
          ...state,
          // supportPersonList: action.payload,
          // totalSupportPersonList: action.payload,
          supportPersonList: action.payload.results,
          totalSupportPersonList: action.payload.count,
        }
      }
      case FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS: {
        return {
          ...state,
          supportPersonListWithNoPagination: action.payload,
        }
      }
      case FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS: {
        return {
          ...state,
          supportPersonOptionList: action.payload,
        }
      }
      case FETCH_ENGINEER_OFFER_LIST_SUCCESS: {
        return {
          ...state,
          engineerOffersList: action.payload.results,
          totalEngineerOffers: action.payload.count,
        }
      }
      case FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS: {
        return {
          ...state,
          engineerJoiningPendingOffersList: action.payload.results,
          totalEngineerJoiningPendingOffers: action.payload.count,
        }
      }
      case FETCH_SINGLE_ENGINEER_OFFER_SUCCESS: {
        return {
          ...state,
          individualEngineerOffersList: action.payload.results,
          totalIndividualEngineerOffersCount: action.payload.count,
        }
      }
      case FETCH_PROJECT_COORDINATORS_LIST_SUCCESS: {
        return {
          ...state,
          projectCoordinatorsList: action.payload.results,
          totalProjectCoordinatorsListList: action.payload.count,
        }
      }
      case FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS: {
        return {
          ...state,
          projectCoordinatorsNoPaginationList: action.payload,
        }
      }
      case FETCH_TIMESHEET_CONFIG_LIST_SUCCESS: {
        return {
          ...state,
          timeSheetConfigList: action.payload.results,
          timeSheetConfigEmail: action.payload.timesheet_email,
        }
      }
      case FETCH_TIMESHEET_HOURS_LIST_SUCCESS: {
        return {
          ...state,
          timeSheetHoursList: action.payload.results,
        }
      }
      case FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS: {
        return {
          ...state,
          timeSheetAttachmentList: action.payload.results,
        }
      }
      case FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS: {
        return {
          ...state,
          vendorTimeSheetAttachmentList: action.payload.results,
        }
      }
      case FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS: {
        return {
          ...state,
          contractTeamOffersList: action.payload.results,
          totalContractTeamOffersCount: action.payload.count,
        }
      }
      case FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS: {
        return {
          ...state,
          contractIncompleteVerificationOfferList: action.payload.results,
          totalcontractIncompleteVerificationOffer: action.payload.count,
        }
      }
      case FETCH_CONTRACT_VERIFIED_LIST_SUCCESS: {
        return {
          ...state,
          verifiedContractOfferList: action.payload.results,
          totalVerifiedContractOfferList: action.payload.count,
        }
      }
      case FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS: {
        return {
          ...state,
          contractTeamDoucumentList: action.payload.results,
        }
      }
      case FETCH_CONSULTANT_OFFER_LIST_SUCCESS: {
        return {
          ...state,
          consultantOffersList: action.payload.results,
          totalConsultantOffersCount: action.payload.count
        }
      }
      
      default:
        return state;
    }
  }
  